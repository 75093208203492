@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular.eot');
    src: url('../fonts/lato-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular.woff') format('woff'),
         url('../fonts/lato-regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bold.eot');
    src: url('../fonts/lato-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bold.woff') format('woff'),
         url('../fonts/lato-bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-italic.eot');
    src: url('../fonts/lato-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-italic.woff') format('woff'),
         url('../fonts/lato-italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'LatoLight';
    src: url('../fonts/lato-light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-light.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'LatoBlack';
    src: url('../fonts/lato-black.eot');
    src: url('../fonts/lato-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-black.woff') format('woff'),
         url('../fonts/lato-black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'latomedium';
    src: url('../fonts/lato-medium.eot');
    src: url('../fonts/lato-medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-medium.woff2') format('woff2'),
         url('../fonts/lato-medium.woff') format('woff'),
         url('../fonts/lato-medium.ttf') format('truetype'),
         url('../fonts/lato-medium.svg#latomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'story_bookregular';
    src: url('../fonts/story_book.eot');
    src: url('../fonts/story_book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/story_book.woff2') format('woff2'),
         url('../fonts/story_book.woff') format('woff'),
         url('../fonts/story_book.ttf') format('truetype'),
         url('../fonts/story_book.svg#story_bookregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "epu-checkat";
    src:url("../fonts/epu-checkat.eot");
    src:url("../fonts/epu-checkat.eot?#iefix") format("embedded-opentype"),
    url("../fonts/epu-checkat.woff") format("woff"),
    url("../fonts/epu-checkat.ttf") format("truetype"),
    url("../fonts/epu-checkat.svg#epu-checkat") format("svg");
    font-weight: normal;
    font-style: normal;
}
ul.bjqs-controls.v-centered li.bjqs-prev a:before,
ul.bjqs-controls.v-centered li.bjqs-next a:before,
[data-icon]:before {
    font-family: "epu-checkat" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
div.html > ul > li:before,
.howittwocol ul li:before,
.howitdiagram ul li:before,
.epu-icon:before,
[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "epu-checkat" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
.icon-tumblr:before         { content: "a" }
.icon-Lock:before           { content: "b" }
.icon-user:before           { content: "c" }
ul.bjqs-controls.v-centered li.bjqs-next a:before,
.icon-right:before          { content: "d" }
.icon-pinterest:before      { content: "e" }
.icon-linkedin:before       { content: "f" }
ul.bjqs-controls.v-centered li.bjqs-prev a:before,
.icon-left:before           { content: "g" }
.icon-xing:before           { content: "h" }
.icon-mail:before           { content: "i" }
.icon-facebook:before       { content: "j" }
.icon-twitter:before        { content: "k" }
.epu-icon:before            { content: "l" }
.icon-graph:before          { content: "m" }
.icon-questionmark:before   { content: "n" }
.icon-users:before          { content: "o" }
.icon-settings:before       { content: "p" }
.icon-youtube:before        { content: "q" }
.icon-check:before,.howitdiagram ul li:before, .howittwocol ul li:before    { content: "r" }
.icon-cancel:before    { content: "s" }
*/


.icon-twitter:before       { content: "a" }
.icon-facebook:before      { content: "b" }
.icon-user:before          { content: "c" }
ul.bjqs-controls.v-centered li.bjqs-next a:before,
.icon-right:before         { content: "d" }
.icon-pinterest:before     { content: "e" }
.icon-linkedin:before      { content: "f" }
ul.bjqs-controls.v-centered li.bjqs-prev a:before,
.icon-left:before          { content: "g" }
.icon-xing:before          { content: "h" }
.icon-lock:before          { content: "i" }
.icon-tumblr:before        { content: "j" }
.icon-mail:before          { content: "k" }
.epu-icon:before           { content: "l" }
.icon-graph:before         { content: "m" }
.icon-questionmark:before  { content: "n" }
.icon-users:before         { content: "o" }
.icon-settings:before      { content: "p" }
.icon-youtube:before       { content: "q" }
.icon-cancel:before        { content: "r" }
.icon-check:before,.howitdiagram ul li:before, .howittwocol ul li:before,div.html > ul > li:before { content: "s" }
.icon-people:before        { content: "t" }
