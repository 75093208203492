@import url("http://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700");

html {
    height: 100%;
    width: 100%;
}
body {
    overflow-x: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: "Open Sans", "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 100%;
    line-height: 1.5em;
    position: absolute;
    height: 100%
    -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    -ms-transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    background: transparent url(../images/bg1.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.global-overlay {
    position: fixed;
    top: 0;
    left: -100vw;
    height: 100%;
    overflow: visible;
    width: 100%;
}
.overlay {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: -50%;
    background: rgba(32, 35, 45, 0.8);
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    -ms-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    opacity: 0.8;
}
.overlay.skew-part {
    -webkit-transform: skew(-25deg, 0deg);
    -moz-transform: skew(-25deg, 0deg);
    -ms-transform: skew(-25deg, 0deg);
    -o-transform: skew(-25deg, 0deg);
    transform: skew(-25deg, 0deg)
}

.woolly-logo {
    position: absolute;
    left: 10%;
    top: 5%;
    max-width: 250px
}
#left-side {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -moz-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -ms-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -o-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1)
}
#left-side .content {
    position: absolute;
    z-index: 0;
    left: 0;
    padding: 0;
    top: 50vh;
    width: 100%;
    padding: 0 10%;
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -moz-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -ms-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -o-transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    visibility: visible
}

@-webkit-keyframes anim-open {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}
@-moz-keyframes anim-open {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}
@keyframes anim-open {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}
@-webkit-keyframes anim-close {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
}
@-moz-keyframes anim-close {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
}
@keyframes anim-close {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0)
    }
}
@-webkit-keyframes animCloud {
    from {
        -webkit-transform: translateX(100%)
    }
    to {
        -webkit-transform: translateX(-100%)
    }
}
@-moz-keyframes animCloud {
    from {
        -moz-transform: translateX(100%)
    }
    to {
        -moz-transform: translateX(-100%)
    }
}
@keyframes animCloud {
    from {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%)
    }
    to {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

#gradient {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    padding: 0px;
    margin: 0px
}

@media only screen and (max-width: 1024px) {
    .overlay {
        left: 0;
        width: 100%;
        height: 100%
    }
    .overlay.skew-part {
        -webkit-transform: skew(0deg, 0deg);
        -moz-transform: skew(0deg, 0deg);
        -ms-transform: skew(0deg, 0deg);
        -o-transform: skew(0deg, 0deg);
        transform: skew(0deg, 0deg)
    }
    #container {
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0
    }
    #stars,
    #stars2,
    #stars3 {
        margin-left: 0 !important
    }
    .brand-logo {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        margin: 25px 0
    }
    .light-btn {
        padding: 0.7em 0;
        margin-right: 0;
        float: none;
        margin: 0 auto;
        margin-bottom: 15px;
        max-width: 50%
    }
    .action-btn {
        padding: 0.7em 0;
        float: none;
        margin: 0 auto;
        max-width: 50%
    }
    #left-side {
        position: relative;
        width: 100%;
        height: auto;
        padding: 100px 0 150px
    }
    #left-side .content {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        top: auto;
        text-align: center
    }
    .social-icons {
        left: 0;
        bottom: 0;
        margin-left: 0;
        margin-bottom: 0
    }
    #right-side {
        position: relative;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        top: 0;
        width: 100%
    }
    #right-side.hide-right {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    #close-more-info {
        display: none
    }
    .mCSB_scrollTools {
        right: 0
    }
    .mCSB_scrollTools-left {
        right: 0 !important
    }
}
@media only screen and (max-width: 768px) {
    .light-btn {
        max-width: 70%;
        min-width: 60%
    }
    .action-btn {
        max-width: 70%;
        min-width: 60%
    }
    .photo-line figure {
        padding: 0;
        margin: 0 0 20px !important;
        width: 100% !important;
        -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2)
    }
    .info-contact .item-map:first-child {
        padding-left: 0
    }
    .info-contact .item-map:last-child {
        padding-right: 0
    }
    .dialog__content {
        width: 80%;
        max-width: 80%;
        min-width: 75%
    }
    .dialog .dialog-inner {
        padding: 40px 20px 90px;
        overflow: hidden
    }
}
@media only screen and (max-width: 480px) {
    #left-side {
        padding: 50px 0 100px
    }
    #left-side .content {
        padding: 0 3%
    }
    #left-side.minimal-phone {
        height: 100vh
    }
    h1.text-intro {
        font-size: 30px
    }
    h2.text-intro {
        font-size: 18px
    }
    .light-btn {
        max-width: 80%;
        min-width: 70%
    }
    .action-btn {
        max-width: 80%;
        min-width: 70%
    }
    .dialog__content {
        width: 95%;
        max-width: 95%;
        min-width: 75%
    }
    .dialog .close-newsletter {
        top: 2px;
        right: 5px
    }
    .dialog .dialog-inner {
        padding: 40px 20px 50px
    }
    .dialog .dialog-inner h4 {
        font-size: 25px;
        margin-bottom: 20px
    }
    #subscribe .block-message {
        padding: 5px 2px
    }
    #subscribe p.notify-valid {
        font-size: 12px
    }
}
@media screen and (max-device-width: 667px) and (orientation: landscape) {
    #left-side {
        padding: 50px 0 100px
    }
    h1.text-intro {
        font-size: 30px
    }
    h2.text-intro {
        font-size: 18px
    }

}
h1 {
    font-size: 40px;
    font-family: Montserrat,"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
    font-weight: 700;
    color: #fff;
    line-height: 1.3em;
}
h2 {
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 30px;
    color: #fff;
    line-height: 1.3em;
    font-weight: 400;
}